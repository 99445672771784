:root {
    --mainColor: #ff4157;
    --bodyCOlor: linear-gradient(to left, #ebf1fc 0%, #faf4f0 100%);
    --bannerColor: #ffffff;
    --headerColor: #ffffff;
    --formColor: #ffffff;
    --fontFamily: "Lato", sans-serif;
}

@import "./css/vendor.bundle";
@import "./css/style";
@import "./css/override-alaska";
@import "./css/theme";

.bg-banner{
    background-color: var(--bannerColor);
}

.bg-header{
    background-color: var(--headerColor);
}

.bg-form{
    background-color: var(--formColor);
}

.feature-icon{
    img{
        width: 70px;
        height: 70px;
        object-fit: contain;
    }
}

.projects-grid{
    display: grid;
    grid-template-areas: "post-1 post-2 post-3"  
                        "post-4 post-4 post-5"  
                        "post-4 post-4 post-6"; 
    grid-template-columns: repeat(3, 1fr);
    gap: 30px;

    .projects-grid-item:nth-child(1) {
        grid-area: post-1;
    }
    .projects-grid-item:nth-child(2) {           
        grid-area: post-2;
    }
    .projects-grid-item:nth-child(3) {
        grid-area: post-3;
    }
    .projects-grid-item:nth-child(4){           
        grid-area: post-4;
    }
    .projects-grid-item:nth-child(5) {
        grid-area: post-5;
    }
    .projects-grid-item:nth-child(6) {           
        grid-area: post-6;
    }
}

@media(max-width: 912px){
    .projects-grid{
        display: grid;
        grid-template-areas: "post-1 post-2"  
                            "post-3 post-4"  
                            "post-5 post-6"; 
        grid-template-columns: repeat(2, 1fr);
        gap: 30px;
    } 
}

@media(max-width: 767px){
    .projects-grid{
        display: flex;
        flex-direction: column;
        padding: 15px;
    } 
}

.width100{
    width: 100%;
}

.red{
    color: red;
}

.green{
    color: green;
}